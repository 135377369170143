import React from 'react';
import './App.css';
import Menu from './menu';
import InfiniteScroll from './InfiniteScroll';




function App() {

  return (
    <div className="App">
    
      <Menu />
      <InfiniteScroll 
      />

    </div>
  );


}

export default App;
