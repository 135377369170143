import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import GridContainer from './GridContainer';


gsap.registerPlugin(ScrollToPlugin);


const InfiniteScroll = () => {
  const gridRef = useRef(null);

  useEffect(() => {
    // Scroll to .grid element 100px below the top of the page
    const gridElement = document.querySelector('.grid');
    if (gridElement) {
      // Disable user scrolling
      document.body.style.overflow = 'hidden';
  
      gsap.to(window, {
        duration: 1, 
        scrollTo: {y: gridElement.offsetTop - window.innerHeight / 2},
        onComplete: () => {
          // Enable user scrolling after animation completes
          document.body.style.overflow = 'auto';
        }
      });
    }

    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.muted = true;
            entry.target.play().catch((error) => {
              console.log('Play request was interrupted:', error);
            });
          } else {
            entry.target.pause();
          }
        });
      },
      {
        threshold: 0.9,
      }
    );

    const observeVideos = () => {
      const videoElements = document.querySelectorAll('video');
      videoElements.forEach((video) => {
        observer.observe(video);
      });
    };

    observeVideos();
    const mutationObserver = new MutationObserver(observeVideos);
    mutationObserver.observe(document.body, { childList: true, subtree: true });

    const checkScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight + 2 * window.innerHeight) {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
        observer.disconnect();
        mutationObserver.disconnect();
        window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return <GridContainer/>;
};

export default InfiniteScroll;