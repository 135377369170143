// Menu.jsx
import React, { useState } from 'react';
import LongText from './LongText';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  //http://localhost:1337/api/about
  //https://romantic-beef-b23c8427f1.strapiapp.com/api/about
fetch('https://romantic-beef-b23c8427f1.strapiapp.com/api/about')
  .then(response => response.json())
  .then(data => {
    // Accessing properties inside 'attributes' which is inside 'data'
    let emailLink = document.getElementById('email-link');
    emailLink.setAttribute('href', `mailto:${data.data.attributes.eMail}`);
    emailLink.textContent = data.data.attributes.eMail;
  })
  .catch((error) => {
    console.error('Error:', error);
  });
  

  return (
    <div className={`site__header ${isOpen ? 'expanded' : ''}`}>
      <div className={`site__header__text ${isOpen ? 'expanded' : ''}`}>
        <span className='site__title'>Office of Christopher Davenport</span>
        <span className='site__subtitle'>Visual Arts and Narrative Spaces</span>
        <div className={`site__header__contact ${isOpen ? 'expanded' : ''}`}>   
            <div className=''><a id="email-link"></a></div>
            <div className=''><a href='https://www.instagram.com/christopherpauldavenport/' target="_blank" rel="noopener noreferrer">Instagram</a></div>
            <div className=''><a href='https://www.the-experience-machine.com/' target="_blank" rel="noopener noreferrer">the-experience-machine.com</a></div>
            <div className=''><a href='https://one-is.com/' target="_blank" rel="noopener noreferrer">one-is.com</a></div>
            
         
        </div>
      </div>
      <div className='bar__burger'  onClick={toggleOpen}>
        <div className={`bar1 ${isOpen ? 'expanded' : ''}`}></div>
        <div className={`bar2 ${isOpen ? 'expanded' : ''}`}></div>
        <div className={`bar3 ${isOpen ? 'expanded' : ''}`}></div>
      </div>
      {<LongText isOpen={isOpen} />}
      <div className={`colophon ${isOpen ? 'expanded' : ''}`}>Designed and Developed by OCD</div>
    </div>
  );
};

export default Menu;


