// Grid.jsx
import React, { forwardRef, useState } from 'react';
import GridItem from './GridItem';
import GridTitle from './GridTitle';
import Modal from './Modal';

const Grid = forwardRef(({ gridItems }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [mediaSrcHighRes, setMediaSrcHighRes] = useState(null);
    const [mediaType, setMediaType] = useState(null);
    const [metaData, setMetaData] = useState(null);

    let previousFragmentID = null;
    return (
        <div className='grid'>
            {gridItems.map((item, index) => {
                let gridTitle = null;
                if (item.fragmentID !== previousFragmentID) {
                    previousFragmentID = item.fragmentID;
                    gridTitle = (
                        <GridTitle 
                            key={`title-${index}`} 
                            item={item}
                        />
                    );
                }
                return (
                    <React.Fragment key={index}>
                        {gridTitle}
                        <GridItem 
                            key={item.id} 
                            item={item} 
                            setIsOpen={setIsOpen}
                            setMediaSrcHighRes={setMediaSrcHighRes}
                            setMediaType={setMediaType}
                            setMetaData={setMetaData}
                        />
                    </React.Fragment>
                );
            })}
            <Modal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                mediaSrcHighRes={mediaSrcHighRes} 
                mediaType={mediaType} 
                closeModal={() => setIsOpen(false)} 
                metaData={metaData}
            />
        </div>
    );
});

export default Grid;