import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const Modal = ({ isOpen, setIsOpen, mediaSrcHighRes, mediaType, closeModal, metaData }) => {
    const modalContainer = useRef(null);
    const modalContent = useRef(null);
    const modalTitle = useRef(null);
    const modalBlurb = useRef(null);
    var tlopen = gsap.timeline();
    var tlclose = gsap.timeline();

    const [isClosing, setIsClosing] = useState(false);
    
    const handleCloseModal = () => {
        setIsClosing(true);
            
        tlclose.to(modalContent.current, {
            scale: 0.98,
            opacity: 0,
            duration: 0.1,
            ease: 'power1.out'
        });

        tlclose.to(modalBlurb.current, 
            { yPercent: 150, 
         
            duration: 0.2, 
            ease: 'power1.out' }, "-=0.1");

        tlclose.to(modalTitle.current, 
            { yPercent: -150, 
        
            duration: 0.2, 
            ease: 'power1.out' }, "-=0.2");

        tlclose.to(modalContainer.current, {
            'backdrop-filter': 'blur(0px) saturate(100%)',
            '-webkit-backdrop-filter': 'blur(0px) saturate(100%)',
            duration: 0.4, 
            ease: 'power1.out',
            onComplete: () => {
                closeModal();
                setIsOpen(false);
                setIsClosing(false);
            }
                                    }, "-=0.2");
    };

   useEffect(() => {
           if (modalContainer.current && modalContent.current && modalTitle.current && modalBlurb.current) {
        // Existing GSAP animations here
            tlopen.fromTo(modalContainer.current, 
                
                { 'backdrop-filter': 'blur(0px) saturate(100%)', '-webkit-backdrop-filter': 'blur(0px) saturate(100%)'}, 
                { 'backdrop-filter': 'blur(300px) saturate(400%)', '-webkit-backdrop-filter': 'blur(300px) saturate(400%)', 
                
                ease: 'power1.in',
                duration: 0.4 });

            tlopen.fromTo(modalTitle.current, 
                { yPercent: -150 }, 
                { yPercent: 0,
                duration: 0.2, 
                ease: 'power1.in' },"-=0.2")

            tlopen.fromTo(modalBlurb.current, 
                    { yPercent: 150 }, 
                    { yPercent: 0,
                    duration: 0.2, 
                    ease: 'power1.in' },"-=0.2")

            tlopen.fromTo(modalContent.current, 
                { opacity: 0, scale: 0.95}, 
                { opacity: 1, scale: 1,
                duration: 0.05, 
                ease: 'power1.in' 
            
            
                                 },"-=0.1")

                                }
          }, [isOpen, modalContainer, modalContent, modalTitle, modalBlurb]);


    useEffect(() => {
        const handleScroll = (event) => {
            if (event.isTrusted && !isClosing) {
                handleCloseModal();
            }
        };
    
        if (isOpen) {
            window.addEventListener('scroll', handleScroll, { passive: false});
        }
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen, handleCloseModal, isClosing]);

    
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal" onClick={handleCloseModal} ref={modalContainer}>
            
            <span ref={modalTitle} className="media__meta__title">{metaData.date}_{metaData.provenance}_{metaData.fragmentTitle}</span>
            
            {mediaType === 'video' ? (
                <video ref={modalContent} src={mediaSrcHighRes} controls autoPlay loop onClick={(e) => e.stopPropagation()} />
            ) : (
                <img ref={modalContent} src={mediaSrcHighRes} alt="High resolution" />
            )}
           
            <span ref={modalBlurb} className="media__meta__blurb">{metaData.mediaBlurb}</span>
        </div>
    );
};

export default Modal;

