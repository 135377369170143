// GridContainer.jsx
import React, { useState, useEffect } from 'react';
import Grid from './Grid';

const GridContainer = () => {
    const [gridItems, setGridItems] = useState([]);

    useEffect(() => {
        //http://localhost:1337/api/grid-items
        //https://romantic-beef-b23c8427f1.strapiapp.com/api/grid-items
        fetch('https://romantic-beef-b23c8427f1.strapiapp.com/api/grid-items')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (!data.data || !Array.isArray(data.data)) {
                    throw new Error('Unexpected format for fetched data');
                }
                const items = data.data.map(item => {
                    if (!item.attributes) {
                        throw new Error('Unexpected format for an item in fetched data');
                    }
                    return item.attributes;
                });
                items.sort((a, b) => {
                    if (a.fragmentID === b.fragmentID) {
                      return a.order - b.order; // Sort by 'order' if 'fragmentID' is the same
                    }
                    return a.fragmentID - b.fragmentID; // Sort by 'fragmentID' in ascending order
                  });
                setGridItems(items);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <>
            
            <Grid gridItems={gridItems} />
            
      
            
        </>
    );
};

export default GridContainer;