import React, { useState, useEffect } from 'react';


let colors = ['#801818', '#d5a933', '#d7ada5', '#688b6f', '#a3574a', '#e9d7b8', '#9d8375'];
let colorIndex = 0;

// Function to shuffle an array
function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

colors = shuffle(colors);

const GridTitle = ({ item }) => {
    const {date, fragmentTitle, provenance, link, fragmentID, role, fragmentTexts } = item;
    const [color, setColor] = useState('');

    useEffect(() => {
        setColor(colors[colorIndex]);
        colorIndex = (colorIndex + 1) % colors.length;
    }, [fragmentID]);
    
    return (
    

    <div className='titlecard__wrapper'> 

      <div className={`grid__item grid__item__titlecard`} data-id={fragmentID} style={{backgroundColor: color}}>
        <span className="fragment__title">{date}_{provenance}_{fragmentTitle}</span>
        <span className="fragment__blurb">{fragmentTexts}</span>
       

        {link && (
         <div className="fragment__link__container">
            
                <a href={link} className="fragment__link" target="_blank" rel="noopener noreferrer">
                Link
                </a>
            
        </div>
        )}

        <div className="fragment__context">

            <ul className='fragment__context__left'>
                <li>Production Context</li>
                <li>Roles</li>
            </ul>

            <ul className='fragment__context__right'>
                <li>{provenance}</li>
                <li>{role}</li>
            </ul>
           
        </div>
        </div>

    </div>
       

    );
  };

export default GridTitle;