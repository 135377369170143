import React, { useState, useEffect } from 'react';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const GridItem = ({ item, setIsOpen, setMediaSrcHighRes, setMediaType, setMetaData }) => {
    const { fragmentID, mediaSrc, mediaSrcHighRes, mediaType, date, mediaBlurb, fragmentTitle, provenance, isPortrait } = item;

    // Generate a random flex-basis value between 15 and 30
    const [randomFlexBasis] = useState(Math.random() * (30 - 10) + 10);

    // Add a new state variable for portrait
    const [portrait, setIsPortrait] = useState(isPortrait);

    const handleClick = () => {
        setIsOpen(true);
        setMediaSrcHighRes(mediaSrcHighRes);
        setMediaType(mediaType);
        setMetaData({ date, mediaBlurb, provenance, fragmentTitle });
    };

    const [videos, setVideos] = useState([]);



    useEffect(() => {
      // Create an intersection observer
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // If the video is in the viewport, play it. If not, pause it.
            if (entry.isIntersecting) {
              entry.target.muted = true; // Mute the video
              entry.target.play().catch((error) => {
                // Handle the error
                console.log('Play request was interrupted:', error);
              });
            } else {
              entry.target.pause();
            }
          });
        },
        {
          threshold: 0.9, // The video needs to be 90% in the viewport to start playing
        }
      );
  
      // Observe all videos
      const videoElements = document.querySelectorAll('video');
      videoElements.forEach((video) => {
        observer.observe(video);
      });
  
      // Cleanup function to disconnect the observer when the component unmounts
      return () => observer.disconnect();
    }, [videos]);

    if (mediaType === "image") {
        return (
            <div className="grid__item" data-id={fragmentID} onClick={handleClick} style={{flexBasis: `${randomFlexBasis}%`}}>
                <LazyLoadImage
                    src={mediaSrc}
                    alt={fragmentTitle}
                    className={isPortrait ? 'portrait' : ''}
                    effect="blur"
                />
                {/* other elements as needed */}
            </div>
        );
    } else if (mediaType === "video") {
        return (
            <div className="grid__item" data-id={fragmentID} onClick={handleClick} >
            <LazyLoadComponent effect="blur">
                <video
                    src={mediaSrc}
                    className={isPortrait ? 'portrait' : ''}
                    loop="loop" 
                    autoPlay="autoplay" 
                    muted={true}
                    playsInline={true}
                />
            </LazyLoadComponent>
            {/* other elements as needed */}
        </div>
        );
    } else {
        return null; // or some default/fallback rendering
    }
};

export default GridItem;