import React, { useEffect, useState } from 'react';

const LongText = ({ isOpen }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        //http://localhost:1337/api/about
        //https://romantic-beef-b23c8427f1.strapiapp.com/api/about
        fetch('https://romantic-beef-b23c8427f1.strapiapp.com/api/about')
            .then(response => response.json())
            .then(data => setData(data.data.attributes.About));
    }, []);

    return (
        <div className={`site__header__longtext ${isOpen ? 'expanded' : ''}`}>
            {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
        </div>
    );
};

export default LongText;